import { createStyles } from '@mantine/styles';


export default createStyles(
    (
        theme: {
            colors: { [x: string]: any[] };
            colorScheme: string;
            breakpoints: any;
            white: string;
            black: string;
            fontFamily: string;
            spacing: any;
            fontSizes: any;
            shadows: any;
        }) => ({

        paper: {
            margin: '0 auto',
            textAlign: 'justify',
            fontSize: 13,
            maxWidth: 500,
            padding: 20,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3],
            boxShadow: theme.shadows.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[7],
        },

        h1: {
            textAlign: 'center',
            letterSpacing: 0.6,
        },

        h2: {
            textAlign: 'center',
            letterSpacing: 0.6,
            color: theme.colors.orange[5],
        },
    })
);
