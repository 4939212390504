import React                             from 'react';
import { Container, Paper, Text, Title } from '@mantine/core';
import { LinkButton }                    from '@core/components/common/LinkButton';
import { AppRoutes }                     from '@core/router/AppRoutes';
import { LoginForm }                     from '@features/auth/login/components/LoginForm';
import useStyles                         from './LoginPageLanding.styles';


export const LoginPageLanding: React.FC = () => {
    const { classes } = useStyles();

    return (
        <Container>
            <Paper className={classes.paper}>
                <Title className={classes.h1} order={1}>
                    Welcome back!
                </Title>

                <Text color="dimmed" size="sm" align="center" mb={30} mt={5}>
                    Do not have an account yet?{' '}

                    <LinkButton title="Create account" route={AppRoutes.REGISTER}/>

                </Text>

                <LoginForm/>

            </Paper>
            </Container>
    );
};
