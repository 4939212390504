import React                from 'react';
import { NextPage }         from 'next';
import AppHead              from '@core/components/common/AppHead';
import AppLayout            from '@layouts/AppLayout';
import { LoginPageLanding } from '@features/auth/login/containers/LoginPageLanding';


const LoginPage: NextPage = () => {
    return (
        <LoginPageLanding/>
    );
};

// @ts-expect-error
LoginPage.getLayout = function getLayout(page: typeof LoginPage) {
    return (
        <>
            <AppHead
                title="Log In"
                description="You need to log in to our website in order to book one of our properties."/>

            <AppLayout requireAuth={false}>
                {/*// @ts-expect-error*/}
                {page}
            </AppLayout>
        </>
    );
};

export default LoginPage;
