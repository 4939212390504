import { createStyles } from '@mantine/styles';


export default createStyles(
    (
        theme: {
            colors: { [x: string]: any[] };
            colorScheme: string;
            breakpoints: any;
            white: string;
            black: string;
            fontFamily: string;
            spacing: any;
            fontSizes: any;
        }
        // { floating }: { floating: boolean }
    ) => ({

        form: {
            // borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]}`,
            // minHeight: 900,
            // maxWidth: 450,
            // paddingTop: 80,

            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                maxWidth: '100%',
            },
        },

        submitButton: {
            fontWeight: 500,
            textTransform: 'uppercase',
            letterSpacing: 1,
        },

    })
);
