import React                           from 'react';
import { createStyles, PasswordInput } from '@mantine/core';
import { FormFieldType }               from '@core/components/form/skin-active/form-field-type';
import { IconLock }                    from '@tabler/icons';


const useStyles = createStyles((
    theme: {
        colors: { [x: string]: any[] };
        colorScheme: string;
        fn: any;
        fontSizes: any;
        spacing: any;
    }) => ({

    passwordInput: {
        position: 'relative',

        '& .mantine-PasswordInput-input': {
            height: 'auto',
            paddingTop: 78,

        },

        '& .mantine-PasswordInput-innerInput': {
            height: 'auto',
            paddingTop: 42,
            paddingBottom: 4,
            fontSize: 14,
        },

        '& .mantine-PasswordInput-icon': {
            top: 40,
            fontSize: 20,
        },

        '& .mantine-PasswordInput-label': {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: theme.fontSizes.sm,
            paddingLeft: theme.spacing.sm,
            paddingTop: 20 / 2,
            zIndex: 1,
        },

        '& .mantine-PasswordInput-description': {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: theme.fontSizes.xs,
            paddingLeft: theme.spacing.sm,
            paddingTop: 28,
            zIndex: 1,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        },

        '& .mantine-PasswordInput-innerInput::placeholder': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        },

        '& .mantine-PasswordInput-error': {
            fontSize: 12,
            paddingTop: 6,
        },

    },

}));


export const PasswordField: React.FC<FormFieldType<any>> = ({
                                                                variable,
                                                                form,
                                                                errorMsg,
                                                                fieldLabel,
                                                                placeholder,
                                                                description,
                                                                required,
                                                                disabled
                                                            }) => {
    const { classes } = useStyles();


    return (
        <PasswordInput
            className={classes.passwordInput}
            mt="sm"
            radius="xs"
            label={fieldLabel}
            placeholder={placeholder}
            description={description}
            error={errorMsg}
            required={required}
            disabled={disabled}
            icon={<IconLock size={16} stroke={1}/>}
            // classNames={{ input: classes.invalid }}
            /*visibilityToggleIcon={({ reveal, size }) =>
                reveal ? <IconEyeOff size={size} /> : <IconEyeCheck size={size} />
            }*/
            {...form.getInputProps(variable)}
        />
    );
};
