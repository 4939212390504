import React                       from 'react';
import { createStyles, TextInput } from '@mantine/core';
import { FormFieldType }           from '@core/components/form/skin-active/form-field-type';
import { IconAt }                  from '@tabler/icons';


const useStyles = createStyles((
    theme: {
        colors: { [x: string]: any[] };
        colorScheme: string;
        fn: any;
        fontSizes: any;
        spacing: any;
    }) => ({

    textInput: {
        position: 'relative',

        'input': {
            height: 'auto',
            paddingTop: 40,
            paddingBottom: 4,
            fontSize: 14,
        },

        '& .mantine-Input-icon': {
            top: 38,
            fontSize: 20,
        },

        'label': {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: theme.fontSizes.sm,
            paddingLeft: theme.spacing.sm,
            paddingTop: 20 / 2,
            zIndex: 1,
        },

        '& .mantine-TextInput-description': {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: theme.fontSizes.xs,
            paddingLeft: theme.spacing.sm,
            paddingTop: 28,
            zIndex: 1,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        },

        '& .mantine-TextInput-input::placeholder': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
        },

        '& .mantine-InputWrapper-error': {
            fontSize: 12,
            paddingTop: 6,
        },

    },

}));

export const EmailField: React.FC<FormFieldType<any>> = ({ variable, form, defaultValue, errorMsg, fieldLabel, placeholder, description, inputVariant, required, disabled }) => {
    const { classes } = useStyles();

    return (
        <TextInput
            className={classes.textInput}
            mt="sm"
            radius="xs"
            label={fieldLabel}
            placeholder={placeholder}
            description={description}
            error={errorMsg}
            required={required}
            disabled={disabled}
            variant={inputVariant}
            defaultValue={defaultValue}
            icon={<IconAt size={16} stroke={1}/>}
            // classNames={{ input: classes.invalid }}
            // rightSection={<Loader size="xs" />}
            // rightSection={<IconAlertTriangle stroke={1.5} size={16} className={classes.icon} />}
            {...form.getInputProps(variable)}
        />
    );
};
