import useUserData                       from '@features/common/hooks/use-user-data';
import { useGetUserFavPropIdsLazyQuery } from '../../../generated/graphql';


/**
 * This Hooks depends on User Data
 * It won't work until user is logged in
 * */
function useFavPropData() {

    const { isUser, getUserId, setUserPropIds } = useUserData();

    const [getUserFavPropIds] = useGetUserFavPropIdsLazyQuery();

    const getFavPropIds = async (): Promise<boolean> => {

        if (isUser()) {

            try {

                const result = await getUserFavPropIds({
                    variables: {
                        userId: getUserId().toString()
                    }
                });

                // console.log(result.data.getUserFavPropIds, 'idResp')

                setUserPropIds(result.data.getUserFavPropIds);

                return true;

            } catch (e) {
                return Promise.reject(e);
            }
        }
    }

    return {
        getFavPropIds
    }
}

export default useFavPropData;
