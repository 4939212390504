import React, { useEffect, useRef, useState } from 'react';
import { useRouter }                          from 'next/router';
import { signIn }                             from 'next-auth/react';
import { Button, Group }                      from '@mantine/core';
import { useForm }                            from '@mantine/form';
import { validateEmail, validateInputLength } from '@core/utils';
import { LinkButton }                         from '@core/components/common/LinkButton';
import { EmailField }                         from '@core/components/form/skin-active/EmailField';
import { PasswordField }                      from '@core/components/form/skin-active/PasswordField';
import { AppRoutes }                          from '@core/router/AppRoutes';
import useFavPropData                         from '@features/common/hooks/use-fav-prop-data';
import useUserProfileStatus                   from '@features/common/hooks/use-user-profile-status';
import useAppData                             from '@hooks/use-app-data';
import useNotification                        from '@hooks/useNotification';
import useStyles                              from './LoginForm.styles';


interface FormValues {
    email: string;
    password: string;
}

// ONCE THE USER IS LOGGED IN GET THE - USER FAV PROP IDs

export const LoginForm: React.FC = () => {

    const mountLock = useRef({ isFirst: false, isLoggedIn: false, response: null, email: null });
    const { classes } = useStyles();

    const { showLoginErrorNotification } = useNotification();
    const { getFavPropIds } = useFavPropData();
    const { lastPage } = useAppData();
    const { fetchUserStatus } = useUserProfileStatus();

    //<editor-fold desc="LOGIN">
    const [isLoading, setIsLoading] = useState(false);
    const [callError, setCallError] = useState('');
    const router = useRouter();
    //</editor-fold>

    //<editor-fold desc="FORM">
    const form = useForm<FormValues>({
        initialValues: { email: '', password: '' },

        validate: {
            email: (value) => validateEmail(value, 'Please input valid email address.'),
            password: (value) => validateInputLength(value, 2, 'Please input valid email address.'),
        },
    });

    const handleSubmit = async (values: typeof form.values) => {

        // console.log('values onSubmit_' + JSON.stringify(values));

        setIsLoading(true);

        const res = await signIn('credentials', {
            username: values.email,
            password: values.password,
            callbackUrl: lastPage, // === AppRoutes.LOGIN ? AppRoutes.HOME : lastPage, //`${window.location.origin}`,
            redirect: false,
        });

        mountLock.current.email = values.email;

        if (res?.error) {
            setCallError(res.error);

            // console.log(res.error, "res.error")

            setIsLoading(false);

            showLoginErrorNotification(res.error);

        } else {
            setCallError(null);

            // setLoginResp(res);
            mountLock.current.isLoggedIn = true;
            mountLock.current.response = res
        }

    };
    //</editor-fold>

    //<editor-fold desc="INITIAL DATA">
    useEffect(() => {

        if (mountLock.current.isLoggedIn) {

            const route = async () => {
                const targetUrl = mountLock.current.response.url;
                await router.push(targetUrl);
            }

            getFavPropIds()
                .then((res) => {

                    fetchUserStatus(mountLock.current.email)
                        .then((val) => {

                            // console.log(val, "status")

                            if(val.status) {
                                // DONT MOVE UNTIL THE PROP IDS
                                if (res) route().catch(console.error)
                            }

                        })
                        .catch(console.error)

                })
                .catch(console.error)

        }

    }, [mountLock.current.isLoggedIn]);
    //</editor-fold>

    //<editor-fold desc="RENDER">
    return (
        <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>

            <EmailField
                form={form}
                variable="email"
                fieldLabel="Email Address"
                placeholder="example@gmail.com"
                description="Please enter your email address"
            />

            <PasswordField
                form={form}
                variable="password"
                fieldLabel="Password"
                placeholder="DXB#!123#!dxb"
                description="Please enter your password"
            />

            <Group position="right" mt="md">

                <LinkButton title="Forgot password?" route={AppRoutes.FORGET_PASSWORD}/>

            </Group>

            <Button variant="filled" className={classes.submitButton} radius="xs" type="submit" loading={isLoading} fullWidth={true} mt="xl">
                Sign in
            </Button>

        </form>
    );
    //</editor-fold>

};
